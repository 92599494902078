<template>
  <div v-loading="loading">
    <!--    <div class="closeBtn" @click="close">-->
    <!--      <i class="el-icon-close"></i>-->
    <!--    </div>-->
    <div class="content_title">
      <div class="one_title">项目名称</div>
      <div class="tow_title">{{ previewData.name }}</div>
    </div>
    <div class="content_box">
      <div class="content_left">

        <div class="content_imgs">
          <div class="icon">
            <el-image :src="cover_info"></el-image>
            <span>产品图片</span>
          </div>

          <el-image class="imgs" :src="previewData?.cover_info?.[0]">
            <div slot='error' class='image-slot'>
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="content_text">
          <el-divider content-position="center">品牌背书</el-divider>
          {{ previewData.brand_intro }}
        </div>
      </div>
      <div class="content_right">
        <div class="row">
          <div class="icon">
            <img :src="sp"/>
          </div>
          <div class="row_item">
            <div class="label">商品名称及规格</div>
            <div class="value">{{ previewData.product }}</div>
          </div>
        </div>
        <div class="row">
          <div class="icon">
            <img :src="pt"/>
          </div>
          <div class="row_item">
            <div class="label">平台</div>
            <div class="value">{{ String(previewData.platforms) }}</div>
          </div>
        </div>
        <div class="row">
          <div class="icon">
            <img :src="bl"/>
          </div>
          <div class="row_item">
            <div class="label">是否保量</div>
            <div class="value">{{ previewData.is_quantity === 1 ? '是' : '否' }}</div>
          </div>
        </div>
        <div class="row">
          <div class="icon">
            <img :src="fb"/>
          </div>
          <div class="row_item">
            <div class="label">费比</div>
            <div class="value">{{ previewData.qty_policy }}</div>
          </div>
        </div>
        <div class="row">
          <div class="icon">
            <img :src="yj"/>
          </div>
          <div class="row_item">
            <div class="label">直播佣金</div>
            <div class="value">{{ previewData.live_cmn }}</div>
          </div>
        </div>
        <div class="row">
          <div class="icon">
            <img :src="jl"/>
          </div>
          <div class="row_item">
            <div class="label">有无特别激励政策</div>
            <div class="value">{{ previewData.is_policy }}</div>
          </div>
        </div>
        <div class="row">
          <div class="icon">
            <img :src="qw"/>
          </div>
          <div class="row_item">
            <div class="label">期望档期</div>
            <div class="value">
              {{ previewData?.expect_start?.slice(0, 10) }} 至 {{ previewData?.except_end?.slice(0, 10) }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="icon">
            <img :src="zb"/>
          </div>
          <div class="row_item">
            <div class="label">直播机制</div>
            <div class="value">{{ previewData.live_mec }}</div>
          </div>
        </div>
        <div class="row">
          <div class="icon">
            <img :src="ty"/>
          </div>
          <div class="row_item">
            <div class="label">是否全网统一机制</div>
            <div class="value">{{ previewData.unify === 1 ? '是' : '否' }}</div>
          </div>
        </div>
        <div class="row">
          <div class="icon">
            <img :src="dj"/>
          </div>
          <div class="row_item">
            <div class="label">是否有签独家</div>
            <div class="value">{{ previewData.exclusive === 1 ? `是,${previewData.other_activity}` : '否' }}</div>
          </div>
        </div>

        <div class="row">
          <div class="icon">
            <img :src="bj"/>
          </div>
          <div class="row_item">
            <div class="label">是否可签保价1月</div>
            <div class="value">{{ previewData.unify === 1 ? '是' : '否' }}</div>
          </div>
        </div>

      </div>
    </div>
    <div class="content_bottom">
      <div class="box">
        <div class="icon">
          <el-image :src="intro"></el-image>
          <span>产品卖点</span>
        </div>
        <div class="info_box">
          {{ previewData.intro }}

        </div>
      </div>
    </div>
    <div class='opt-box' v-if="action">
      <el-row :gutter='10'>
        <el-col :span='4'>
          <el-button size='medium' icon='el-icon-arrow-left' @click='preBtn'>上一页
          </el-button>
        </el-col>
        <el-col :span='16' style='text-align: center'>
        </el-col>
        <el-col :span='4' style='text-align: right;float: right'>
          <el-button size='medium' @click='nextBtn'>下一页<i
              class='el-icon-arrow-right'></i>
          </el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import cover_info from './assets/cover_info.png'
import intro from './assets/intro.png'
import sp from './assets/sp.png'
import pt from './assets/pt.png'
import bl from './assets/bl.png'
import fb from './assets/fb.png'
import yj from './assets/yj.png'
import jl from './assets/jl.png'
import qw from './assets/qw.png'
import zb from './assets/zb.png'
import ty from './assets/ty.png'
import dj from './assets/dj.png'
import bj from './assets/bj.png'

export default {
  name: 'productDetails',
  props: {
    previewData: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    action: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    previewData: {
      deep: true,
      handler() {
        console.log('previewData', this.previewData)
        this.$forceUpdate()
      }
    }
  },
  data() {
    return {
      infoData: {},
      cover_info: cover_info,
      intro: intro,
      showIndex: 0,
      sp: sp,
      pt: pt,
      bl: bl,
      fb: fb,
      yj: yj,
      jl: jl,
      qw: qw,
      zb: zb,
      ty: ty,
      dj: dj,
      bj: bj
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    preBtn() {
      this.$emit('handleButton', 'preBtn')
    },
    nextBtn() {
      this.$emit('handleButton', 'nextBtn')
    }
  },
  mounted() {
    this.infoData = this.previewData
  }
}

</script>
<style lang="scss" scoped>

.closeBtn {
  position: fixed; /* 绝对定位，不管滚动条上下滚动都在相应的位置*/
  z-index: 999;
  font-size: 30px;
  top: 10px;
  right: 30px;
  cursor: pointer;
}


.content_title {
  width: 80%;
  height: 40px;
  background: #e04140;
  border-radius: 10px;

  .one_title, .tow_title {
    font-size: 20px;
    line-height: 40px;
    float: left;
  }

  .one_title {
    width: 200px;
    background-color: #ebebeb;
    text-align: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: relative;
  }

  .one_title::before {
    content: '';
    right: 0;
    position: absolute;
    left: 100%;
    top: 0;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 40px solid #e04140;
  }

  .tow_title {
    z-index: 2;
    margin-left: 40px;
    color: white;
    letter-spacing: 4px;
  }
}

.content_box {
  margin: 20px 0;
  height: 520px;

  .content_left {
    width: 300px;
    background-color: #f0eded;
    border-radius: 10px;
    padding: 20px 0;
    float: left;

    .content_imgs {
      display: block;
      width: 260px;
      height: 260px;
      background-color: white;
      border-radius: 10px;
      margin: 0 auto;
      position: relative;

      .icon {
        position: absolute;
        z-index: 2;
        width: 130px;
        left: 50%;
        transform: translate(-50%);

        > span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .imgs {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }

    .content_text {
      display: block;
      width: 260px;
      margin: 0 auto;
      height: 200px;
      overflow-y: scroll;
    }
  }

  .content_right {
    float: left;
    width: calc(80% - 340px);
    padding: 10px;
    margin: 0 20px;

    .row {
      display: flex;
      align-items: center; /* 垂直居中 */

      .icon {
        width: 24px;
        height: 24px;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .row_item {
        vertical-align: middle;
        width: calc(100% - 40px);
        border-bottom: 1px solid #cccccc;
        padding-bottom: 10px;
        color: black;
        font-size: 18px;
        margin-top: 13px;
        margin-left: 10px;
        display: flex;

        align-items: center; /* 垂直居中 */
        .label, .value {
          display: inline-block;
        }

        .label {
          width: 200px;
          font-weight: bold;
        }

        .value {
          width: calc(100% - 200px);

        }
      }
    }

  }
}

.content_bottom {
  clear: both;
  width: 100%;
  //min-height: 280px;
  margin: 0 auto;
  background-color: #f0eded;
  overflow: hidden;
  border-radius: 10px;

  .box {
    margin: 10px auto;
    width: 98%;
    min-height: 290px;
    background-color: white;
    border-radius: 10px;
    position: relative;

    .icon {
      height: 100px;
      z-index: 2;
      position: absolute;

      .el-image {
        height: 140px;
        top: 50%;
        left: 18px;
        transform: translate(-50%);
      }

      > span {
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%);
        color: white;
        font-size: 20px;
      }
    }

    .info_box {
      width: 90%;
      margin: 0 auto;
      padding-top: 20px;
      font-size: 18px;
      overflow-y: scroll;
    }
  }
}

.opt-box {
  width: 96%;
  //line-height: 40px;
  position: absolute;
  bottom: 30px;
  opacity: 0.3;
}

.opt-box:hover {
  opacity: 1;
  transition-duration: 0.3s;

}

::v-deep .el-divider__text {
  background-color: #ebebeb;
  font-size: 18px;
  color: #666666;
}

::v-deep .image-slot {
  text-align: center;
  margin: 26% auto;
  width: 50%;
  height: 50%;
  font-size: 100px;
}

</style>