<template>
  <div style='width: 100%'>
    <el-autocomplete
        style='width: 100%'
        :debounce='500'
        :fetch-suggestions='querySearch'
        allow-create
        filterable
        @clear='handleSelect'
        @select='handleSelect'
        clearable
        placeholder='产品检索：输入关键字；如品牌旗下多个产品都可以做则不填'
        v-bind='$attrs'
        v-model='userName'
        v-on='$listeners'
    >
      <!--            <i-->
      <!--                    @click="clickBtn"-->
      <!--                    class="el-icon-search"-->
      <!--                    slot="prefix">-->
      <!--            </i>-->
    </el-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'productSearch',
  props: {
    size: {
      type: String,
      default() {
        return 'mini'
      }
    },
    clear: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      userName: null
    }
  },
  // computed: {
  //   debouncedQuerySearch: {
  //     get() {
  //       // 创建防抖后的方法
  //       return debounce(this.querySearch, 500)
  //     },
  //     cache: true
  //   }
  // },
  methods: {
    async querySearch(queryString, cb) {
      let { list } = await this.$api.getProductOptions({ title: queryString })
      //数据处理
      list = list.map((item) => {
        item.value = `${item.text}（ID:${item.id || '-'}） `
        return item
      })
      cb(list)
    },
    // 处理搜索结果的选中
    handleSelect(item) {
      if (item) {
        this.$emit('update:product_id', item ? item.id : '')
        this.$emit('update:product', item ? item.text : '')
        this.$emit('handleSelect', item)
        // this.clearSelect()
      } else {
        this.$emit('handleSelect', null)
      }

    },
    clearSelect: function() {
      this.userName = null
      this.$emit('handleSelect', {})
    },
    clearData() {
      this.userName = null

    },
    clickBtn() {
      this.$emit('clicked', this.userName)
    }
  }

}
</script>

<style scoped>

</style>
